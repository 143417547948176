import React from 'react';
import { Link } from 'gatsby';
import Script from 'react-load-script';

import { graphql } from 'gatsby';
import Typing from 'react-typing-animation';

export default class IndexPage extends React.Component {
  handleScriptLoad() {
    if (typeof window !== `undefined` && window.netlifyIdentity) {
      window.netlifyIdentity.on('init', user => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/';
          });
        }
      });
    }
    window.netlifyIdentity.init();
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const transition = this.props.transition;

    return (
      <section
        style={transition && transition.style}
        className="section sw-landing"
      >
        <Script
          url="https://identity.netlify.com/v1/netlify-identity-widget.js"
          onLoad={() => this.handleScriptLoad()}
        />
        <div className="container">
          <div className="content">
            <Typing loop={true} speed={5}>
              <span className="post-heading">
                Making a JIRA ticket for that.
              </span>
              <Typing.Delay ms={1000} />
              <Typing.Backspace count={40} />
              <Typing.Delay ms={100} />
              <span className="post-heading">
                Pulling from master for that.
              </span>
              <Typing.Delay ms={1000} />
              <Typing.Backspace count={40} />
              <Typing.Delay ms={100} />
              <span className="post-heading">
                Writing a cronjob for that.
              </span>
              <Typing.Delay ms={1000} />
              <Typing.Backspace count={40} />
              <Typing.Delay ms={100} />
              <span className="post-heading">
                Running a batch analysis for that.
              </span>
              <Typing.Delay ms={1000} />
              <Typing.Backspace count={40} />
              <Typing.Delay ms={100} />
            </Typing>
            <br />
            <h1 className="has-text-weight-bold thin-title">Blog</h1>
            <p className="thin-title">Thoughts, ideas, & tutorials.</p>
          </div>
          <hr />

          {posts
            .filter(
              post => post.node.frontmatter.templateKey === 'blog-post',
            )
            .map(({ node: post }) => (
              <div
                className="content"
                style={{
                  // border: '1px solid #eaecee',
                  padding: '1em 4em',
                }}
                key={post.id}
              >
                <Link to={post.frontmatter.path}>
                  <p className="post-heading">
                    {post.frontmatter.title}
                    <span> &bull; </span>
                    <small>{post.frontmatter.date}</small>
                  </p>
                  <p className="post-snippet">
                    {post.frontmatter.description}..
                    {/* <div className="button is-small">...</div> */}
                  </p>
                </Link>
              </div>
            ))}
        </div>
      </section>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;
